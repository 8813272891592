import { createI18n } from "vue-i18n"
import en from "./en.json"
import ar from "./ar.json"
import fr from "./fr.json"

const i18n =  createI18n({
    locale: localStorage.getItem('lang') || 'ar',
    messages: {
        en: en,
        ar: ar,
        fr: fr
    }
});

export default i18n;